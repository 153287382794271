<template>
  <div class="wrap">
    <!-- 상단 고정 해더 영역 -->
    <div class="header-wrap">
      <div class="container">
        <div class="header-group">
          <button type="button" class="nav-btn left" @click="$router.back()"><i class="icon-back"></i></button>
        </div>
      </div>
    </div>
    <!-- 컨텐츠 영역 -->
    <div class="content-wrap-v2">
      <div class="container">
        <div class="row">
          <div class="conp-section-30">
            <h5 class="half-tit">이메일</h5>
            <div class="w-100">
              <div class="pt-30 d-flex-row">
                <MTRInput
                    :type="'email'"
                    :placeholder="'이메일을 입력해 주세요.'"
                    :model="email"
                    @update="(_model) => {this.email=_model}"
                />
                <button type="button" class="l_btn btn-black ml-10" @click="checkEmail()">확인</button>
              </div>
              <p class="alert-red mt-15 text-c" v-show="errors.email">{{ errors.email }}</p>
              <p class="alert-green mt-15 text-c" v-show="isChecked">사용 가능한 이메일입니다.</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="button-wrap">
      <div class="container">
        <div class="button-group">
          <button class="btn btn-black" :class="{'btn-disable' : !isChecked}" @click="goNext()">다음</button>
        </div>
      </div>
    </div>
    <form id="nice-form" action="https://nice.checkplus.co.kr/CheckPlusSafeModel/checkplus.cb" method="post">
      <input type="hidden" name="m" value="checkplusService">
      <input type="hidden" name="EncodeData" id="nice-enc">
    </form>
  </div>
</template>

<script>
import MTRInput from "../../common/MTRInput";
import axios from "axios";
export default {
  name: "SignUp02",
  components: {
    MTRInput
  },
  data() {
    return {
      id: '',
      password: '',
      email: '',
      isChecked: false,
      errors: {
        email:'',
      },
      nice: '',
      phone: '',
    }
  },
  mounted() {
    this.id = this.$route.params.id
    this.password = this.$route.params.password
  },
  methods: {
    errorClear() {
      this.errors = { email: ''}
    },
    checkEmail() {

      this.errorClear()
      if (!(/^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i.test(this.email))) {
        this.errors.email = '이메일을 정확하게 입력해주세요.'
        return
      }
      this.$get(this.$AUTH_EMAIL + '?email=' + this.email, 'signup02_checkemail', () => {
        this.isChecked = true
        console.log('1')
      }, (result) => {
        console.log('2')
        if (result.data.error.length > 0) {
          for (let i=0;i<result.data.error.length;i++) {
            let err = result.data.error[i]
            this.errors[err.field] = err.message
          }
        } else if (result.data.message) {
          this.errors.email = result.data.message
        } else {
          this.errors.email = '사용할 수 없는 이메일입니다.'
        }
        this.isChecked = false
      })
    },
    goNext() {
      this.errorClear()
      if (this.isChecked !== true) {
        this.errors.email = '중복 확인을 해주세요.'
        return
      }
      this.niceEncode()
    },

    niceEncode() {
      axios.post("https://nice.reappay.net/check-pay-plus/request", null, {
        /*headers: {
          'Content-Type': 'application/json;charset=UTF-8',
        },*/
        timeout: 60000
      }).then(response => {
        if (response.data.success === true) {
          this.niceShow(response.data.enc_data)
        } else {
          console.log(response.data)
        }
      }).catch((e) => {
        console.log(e)
      })
    },
    niceShow(enc_data) {
      window.removeEventListener('message', this.niceDone)

      window.open('', 'popupChk', 'width=500, height=550, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=yes, location=no, scrollbar=no');
      window.$('#nice-enc').val(enc_data)
      window.$('#nice-form').attr('target', 'popupChk')
      window.$('#nice-form').submit()

      window.addEventListener('message', this.niceDone);
    },
    niceDone(e) {
      if(e.origin === 'https://nice.reappay.net') {
        this.phone = e.data.phone_no.substr(0,3)+'-'+e.data.phone_no.substr(3,4)+'-'+e.data.phone_no.substr(7,4)
        this.nice = e.data.payload

        window.removeEventListener('message', this.niceDone)

        this.niceCheck()
      }
    },
    niceCheck() {
      let formData = new FormData()
      formData.append('auth', this.nice)

      formData.append('email', this.email)
      formData.append('id', this.id)
      formData.append('password', this.password)
      formData.append('passwordCk', this.password)

      this.$post(this.$AUTH_SIGNUP, 'signup02_nicecheck', formData, () => {
        this.$router.push({ name: 'SignUpFinish' })
      }, (result) => {
        this.$router.replace({ name: 'SignUpFail', params: {message : result.data.message} })
      })
    },

  }
}
</script>

<style scoped>
.wrap { position: relative; width: 100%; min-height: 100%; height: 100%; margin: 0 auto; }
</style>
